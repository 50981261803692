.demo-wrapper{
    width: 100%;
    box-sizing: border-box;
    /* height: 23rem; */
    padding: 0;
    min-height: 23rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #D2d2d2;
    border-width: 0.1rem;
    /* margin-bottom: 3.2rem; */
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
}

.demo-editor {
    box-sizing: border-box;
    padding: 0 2rem;
    font-family: 'Pretendard-Regular';
    line-height: 0.8;
    width: 100%;
}

.toolbar-class {
    background-color: #D2d2d2;
    border: none;
    outline: none;
}

.toolbar-button {
    border: none;
    background: none;
    outline: none;
}

.toolbar-button:hover{
    border: none;
    background: none;
}

.job-editor{
    height: 100%;
}

.job-wrapper{
    height: auto;
}